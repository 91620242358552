import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDx_w_5s8BwxRQFnHeexrwI68k_kRNFhos',
  authDomain: 'bobyte.firebaseapp.com',
  databaseURL: 'https://bobyte.firebaseio.com',
  projectId: 'bobyte',
  storageBucket: 'bobyte.appspot.com',
  messagingSenderId: '329145586871',
  appId: '1:329145586871:web:8c6ba1040f705c0ec5e932',
  measurementId: 'G-T2ZYLSM331'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const analytics = getAnalytics(app)
