/* global requestAnimationFrame */
import './style'
import { useCallback } from 'preact/hooks'
import { logEvent } from '@firebase/analytics'
import { analytics } from 'singletons/firebase'
import Router, { route } from 'preact-router'
import Home from 'routes/Home'
import Terms from 'routes/Terms'
import Faq from 'routes/Faq'

export default function App () {
  const handleRouteChange = useCallback((ev) => {
    if (typeof window !== 'undefined') {
      if (ev.url && ev.url.substr(0, 3) === '/qr') {
        logEvent(analytics, 'from_qr_code', {})
        requestAnimationFrame(() => route('/', true))
      }

      logEvent(analytics, 'screen_view', {
        firebase_screen: ev.url,
        firebase_screen_class: 'BobyteApp'
      })
    }
  }, [])

  return (
    <div>
      <Router onChange={handleRouteChange}>
        <Terms path='/terms' />
        <Faq path='/faq' />
        <Home default />
      </Router>
    </div>
  )
}
